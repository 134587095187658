<template>
  <div class="cell-box">
    <van-cell
      v-for="(item, index) in list"
      :title="item.title"
      is-link
      :to="'problemList?pid=' + item.id + '&title=' + item.title"
      :key="index"
    />
  </div>
</template>
<script>
import { Cell } from "vant";
import { problemList } from "../api/guide";
import { wxPublicAuth } from "../api/home";

export default {
  name: "problem",
  components: {
    "van-cell": Cell,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    problemList({
      pid: 0,
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.list = res.data.list;
      } else {
      }
    });

    
  },
  methods: {
    wxPublicAuth() {
      wxPublicAuth({
        page: "home",
      })
        .then((res) => {
          console.log(res);

          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>
<style>
.van-cell__title {
  display: flex;
}
.cell-box {
  padding: 0 1.8rem;
}
</style>


